import React from 'react';
import {
  mf,
  categoriseGifts,
  formatMoney,
  identifiers,
  nzCharityName as charityName,
  getPersonFullName
} from '../index';

export default ({ gifts: data, counter }) => {
  const gifts = data.getGifts;
  const {
    giftsToCharities,
    giftsToPeople,
    giftsToCharitiesOfMoney,
    giftsToCharitiesOfPossessions,
    giftsToPeopleOfMoney,
    giftsToPeopleOfPossessions
  } = categoriseGifts(gifts);

  return (
    <article id="gifts">
      <h2>Gifts</h2>
      {giftsToCharities.length > 0 && (
        <>
          <h3>Charitable Gifts</h3>
          <ol className="primary" start={counter.primary + 1}>
            {giftsToCharitiesOfMoney.length > 0 && (
              <>
                <li data-counter={counter.primary++}>
                  I give to the following charities:
                  <ol type="a">
                    {giftsToCharitiesOfMoney.map((gift, i) => (
                      <li key={i}>
                        {charityName(gift.charity.meta)} of{' '}
                        {mf('address', gift.charity.meta)} the amount of{' '}
                        {formatMoney(mf('amount', gift.meta), 'nz')};
                      </li>
                    ))}
                  </ol>
                </li>
              </>
            )}
            {giftsToCharitiesOfPossessions.length > 0 && (
              <>
                <li data-counter={counter.primary++}>
                  I give to:
                  <ol type="a">
                    {giftsToCharitiesOfPossessions.map((gift, i) => (
                      <li key={i}>
                        {charityName(gift.charity.meta)} of{' '}
                        {mf('address', gift.charity.meta)} my{' '}
                        {mf('description', gift.meta)};
                      </li>
                    ))}
                  </ol>
                </li>
                <li data-counter={counter.primary++}>
                  My Executor must pay the expense of keeping and delivering any
                  gift of charitable property to the beneficiary as though it
                  was a testamentary expense.
                </li>
              </>
            )}
          </ol>
          <ol className="primary" start={counter.primary + 1}>
            <li data-counter={counter.primary++}>
              In respect of these charitable gifts:
              <ol type="a">
                <li>
                  If an interest in a charitable gift cannot take effect
                  completely or at all, then to the extent that it cannot take
                  effect, that interest shall pass to a charitable organisation
                  or organisations in New Zealand, which my Executor in their
                  discretion consider most nearly fulfils or fulfil the objects
                  I intend to benefit through that gift, in such share or shares
                  as my Executor sees fit; and
                </li>
                <li>
                  The receipt of the treasurer, secretary, public officer,
                  delegate or agent for the time being of a beneficiary under
                  this provision is sufficient to discharge to my Executors in
                  respect of a gift to that beneficiary.
                </li>
              </ol>
            </li>
          </ol>
        </>
      )}

      {giftsToPeople.length > 0 && (
        <>
          <h3>Gifts</h3>
          <ol className="primary" start={counter.primary + 1}>
            {giftsToPeopleOfMoney.length > 0 && (
              <>
                <li data-counter={counter.primary++}>
                  I give to those of the following who survive me by 30 days:
                  <ol type="a">
                    {giftsToPeopleOfMoney.map((gift, i) => (
                      <li key={i}>
                        {getPersonFullName(gift.recipientDirectoryPerson)}{' '}
                        {identifiers(gift.recipientDirectoryPerson)} the amount
                        of {formatMoney(mf('amount', gift.meta), 'nz')};
                      </li>
                    ))}
                  </ol>
                </li>
              </>
            )}
            {giftsToPeopleOfPossessions.length > 0 && (
              <>
                <li data-counter={counter.primary++}>
                  I give the following gifts from my estate, provided that the
                  relevant beneficiary survives me by 30 days:
                  <ol type="a">
                    {giftsToPeopleOfPossessions.map((gift, i) => (
                      <li key={i}>
                        {getPersonFullName(gift.recipientDirectoryPerson)}{' '}
                        {identifiers(gift.recipientDirectoryPerson)} my{' '}
                        {mf('description', gift.meta)};
                      </li>
                    ))}
                  </ol>
                </li>
                <li data-counter={counter.primary++}>
                  The beneficiary of the gift must pay the expense of keeping
                  and delivering any gift of specific property to the
                  beneficiary and must reimburse my Executor if my Executor
                  incurs the expense.
                </li>
              </>
            )}
            <li data-counter={counter.primary++}>
              In respect of these specific gifts, if a person referred to above,
              does not survive me by 30 days leaving Children who survive me by
              30 days and have attained or attain their majority, then those
              Children having attained or on attaining their respective
              majorities take equally as tenants in common the share which their
              parent would otherwise have taken.
            </li>
            <li data-counter={counter.primary++}>
              If any of the gifts above wholly fail, that gift will return to my
              estate to be included in my Residuary Estate.
            </li>
          </ol>
        </>
      )}

      <h3>Digital Assets</h3>
      <ol className="primary" start={counter.primary + 1}>
        <li data-counter={counter.primary++}>
          I give to my Executor my digital information, passwords, login
          details, data and documents not otherwise specifically gifted in this
          Will (<strong>Digital Assets</strong>) (as determined by my Executor)
          absolutely and I request my Executor (without imposing any trust or
          binding obligation on my Executor and without conferring any interest
          upon any other person), to add my Digital Assets to, and ensure they
          form part of, my Residuary Estate.
        </li>
      </ol>
    </article>
  );
};
