import React from 'react';
import {
  buildAddress,
  getPersonFullName,
  separateContactsByRole
} from '../index';

export default ({ user, contacts }) => {
  const { ownerPerson } = separateContactsByRole(user, contacts);

  return (
    <article id="attestation" className="page-break">
      <h2>Attestation</h2>
      <p>
        Dated this day, {getPersonFullName(ownerPerson)}{' '}
        {buildAddress(ownerPerson)} signed in the presence of both of us being
        present at the same time, and we attested the Will Maker&apos;s
        signature in the Will Maker&apos;s presence and of each other.
      </p>
      <div className="user-attest">
        <strong className="witness">{getPersonFullName(ownerPerson)}</strong>
        <div className="sign-attest">Signature</div>
        <div className="sign-attest">Date</div>
      </div>
      <div className="split">
        <div className="col">
          <strong className="witness">Witness 1</strong>
          <div className="sign-attest">Witness 1 Name (Print Name)</div>
          <div className="sign-attest">Witness 1 Address</div>
          <div className="sign-attest">Witness 1 Occupation</div>
          <div className="sign-attest">Witness 1 Signature</div>
        </div>
        <div className="col">
          <strong className="witness">Witness 2</strong>
          <div className="sign-attest">Witness 2 Name (Print Name)</div>
          <div className="sign-attest">Witness 2 Address</div>
          <div className="sign-attest">Witness 2 Occupation</div>
          <div className="sign-attest">Witness 2 Signature</div>
        </div>
      </div>
    </article>
  );
};
