import React from 'react';
import { identifiers, getPersonFullName } from '../index';

export default ({ guardians: data, counter }) => {
  const guardians = data.getGuardians;
  const primaryGuardians = guardians.filter((g) => g && g.type === 'primary');
  const primaryGuardianPerson1 = primaryGuardians[0]?.directoryPerson;

  const backupGuardians = guardians.filter((g) => g && g.type === 'backup');
  const backupGuardianPerson1 = backupGuardians[0]?.directoryPerson;

  return (
    <article id="guardians">
      {primaryGuardians.length > 0 && (
        <>
          <h2>Guardianship</h2>
          <ol className="primary" start={counter.primary + 1}>
            <li data-counter={counter.primary++}>
              If any child of mine is under 18 years of age, and I am the only
              living parent with parental responsibility at the date of my
              death, I appoint {getPersonFullName(primaryGuardianPerson1)}{' '}
              {identifiers(primaryGuardianPerson1)} as guardian with care and
              custody of that child or those children.
            </li>

            {backupGuardians.length > 0 && (
              <li data-counter={counter.primary++}>
                If the aforementioned{' '}
                {getPersonFullName(primaryGuardianPerson1)} does not survive me,
                or refuses or is unable for any reason to act as guardian to my
                minor children, then I appoint{' '}
                {getPersonFullName(backupGuardianPerson1)}{' '}
                {identifiers(backupGuardianPerson1)} to be the guardian of my
                minor children.
              </li>
            )}
            <li data-counter={counter.primary++}>
              My Executor may pay the whole or any part of the sum due to a
              beneficiary under the age of 18 years old to the parent or
              guardian of that beneficiary or the person with whom that
              beneficiary resides and the receipt by that payee is a sufficient
              discharge of my Executor&apos;s duty to carry out my testamentary
              wish insofar as a gift is concerned to a giftee or beneficiary
              under the age of 18 years and not having attained the age of
              Majority.
            </li>
          </ol>
        </>
      )}
    </article>
  );
};
