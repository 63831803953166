import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider, createClient } from 'urql';
import App from './App';

function retrieveAuthToken() {
  const url = new URL(window.location.href);
  return url.searchParams.get('auth');
}

const client = createClient({
  url: process.env.REACT_APP_API_URL,
  fetchOptions: {
    headers: {
      Authorization: `Bearer ${retrieveAuthToken()}`
    }
  }
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider value={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
