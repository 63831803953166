import React from 'react';
import {
  mf,
  identifiers,
  buildAddress,
  getPersonFullName,
  getPersonAltFullName,
  separateContactsByRole
} from '../index';

export default ({ will, user, contacts, counter }) => {
  const meta = will.meta;
  const { ownerPerson, partnerPerson, childrenPersons } =
    separateContactsByRole(user, contacts);

  return (
    <article id="preliminary">
      <h1>
        Last Will and Testament of
        <br />
        {getPersonFullName(ownerPerson)}
      </h1>
      <h2>Preliminary Declarations and Scope of Will</h2>
      <p>
        This Will is made by me, {getPersonFullName(ownerPerson)}{' '}
        {buildAddress(ownerPerson)}
        {mf('has_alt_name', meta) === 'true' &&
          getPersonAltFullName(ownerPerson) && (
            <span> (also known as {getPersonAltFullName(ownerPerson)})</span>
          )}{' '}
        (<strong>I</strong>, <strong>me</strong>, the{' '}
        <strong>Will maker</strong>
        ).
      </p>
      <ol className="primary">
        <li data-counter={counter.primary++}>
          If at the time of my death I have made a Will in a jurisdiction other
          than Australia, this Will affects my property and affairs in Australia
          only and does not affect property or affairs of mine in any other
          country.
        </li>
        <li data-counter={counter.primary++}>
          I revoke all previous Wills and testamentary acts made by me in
          respect of property and affairs of mine in Australia only, and I
          declare this to be my last Will.
        </li>
        {mf('funeral_type', meta) === 'cremation' && (
          <li data-counter={counter.primary++}>I wish to be cremated.</li>
        )}
        {mf('funeral_type', meta) === 'burial' && <li>I wish to be buried.</li>}
        {mf('funeral_type', meta) === 'donate' && (
          <li data-counter={counter.primary++}>
            I declare my body available for such anatomical, therapeutic,
            medical or scientific purposes as my Executor considers would best
            achieve my wish.
          </li>
        )}
        {(mf('funeral_type', meta) !== 'donate' ||
          mf('funeral_type', meta) === 'executor') &&
          !mf('funeral_location', meta) && (
            <li data-counter={counter.primary++}>
              I wish for my{' '}
              {mf('funeral_type', meta) === 'burial' ||
              mf('funeral_type', meta) === 'executor'
                ? 'remains to be deposited'
                : 'ashes to be deposited or scattered'}{' '}
              at such place as my Executor determines in their unfettered
              discretion.
            </li>
          )}
        {(mf('funeral_type', meta) !== 'donate' ||
          mf('funeral_type', meta) !== 'executor') &&
        mf('funeral_location', meta) ? (
          <li data-counter={counter.primary++}>
            My{' '}
            {mf('funeral_type', meta) === 'burial'
              ? 'wishes for the location of my burial are as follows:'
              : 'wish is for my ashes to be deposited or scattered at the following location:'}{' '}
            <br />
            <i>{mf('funeral_location', meta)}</i>
            <br />
            or such place as my Executor considers would most closely achieve my
            wish.
          </li>
        ) : null}
        {(mf('has_partner', meta) === 'true' ||
          mf('has_children', meta) === 'true') && (
          <>
            {mf('has_partner', meta) === 'true' && (
              <li data-counter={counter.primary++}>
                My partner is {getPersonFullName(partnerPerson)}{' '}
                {identifiers(partnerPerson)}.
              </li>
            )}
            {mf('has_children', meta) === 'true' && (
              <li data-counter={counter.primary++}>
                I have the following living children:
                <ol type="a">
                  {childrenPersons.map((child, i) => (
                    <li key={i}>
                      {getPersonFullName(child)} {identifiers(child)}.
                    </li>
                  ))}
                </ol>
              </li>
            )}
          </>
        )}
      </ol>
    </article>
  );
};
