import React from 'react';
import { mf } from '../index';

// prettier-ignore
export default ({ counter, will }) => {
  const meta = will?.meta;
  const hasProfessionalExecutors =
    mf('executors_option', meta) === 'professional' ||
    mf('executors_option', meta) === 'friendsFamilyAndProfessional';

  return (
    <article id="definitions">
      <h2>Definitions and Interpretation</h2>
      <ol className="primary" start={counter.primary + 1}>
        <li data-counter={counter.primary++}>
          The following definitions and interpretation apply in this Will unless
          the context requires otherwise:
        </li>
        <h3>Definitions:</h3>
        <ol type="a">
          <li>
            <strong>Backup Beneficiary/ies</strong> has the meaning ascribed to
            that term in the Residuary Estate section of this Will.
          </li>
          <li>
            <strong>Children</strong> means the children of a person (including
            their biological or adopted children) but not a stepchild, surrogate
            child or foster child; and <strong>Child</strong> shall have the
            same meaning.
          </li>
          <li>
            <strong>Executors</strong> means my executors and trustees and my personal representatives for the time being, and shall, where applicable, also include my Substitute Executors; and <strong>Executor</strong> shall have the same meaning.
          </li>
          <li>
            <strong>Majority</strong> means attaining the age of 18 years, and <strong>Majorities</strong> shall have the same meaning.
          </li>
          <li>
            <strong>Primary Beneficiary/ies</strong> has the meaning ascribed to
            that term in the Residuary Estate section of this Will.
          </li>
          <li>
            <strong>Residuary Estate</strong> means all of my assets that are
            left over after all debts, funeral and testamentary expenses and
            specific gifts are provided for.
          </li>
          {hasProfessionalExecutors && (
            <li>
              <strong>Safewill Executor</strong> means:
              <ol type="1">
                <li>
                  if at the date of my death Safewill Estates is a licenced
                  trustee company in accordance with the Corporations Act 2001
                  (Cth) (or successive legislation), Safewill Estates;
                </li>
                <li>
                  if at the date of my death Safewill Estates is not a licenced
                  trustee company in accordance with the Corporations Act 2001
                  (Cth) (or successive legislation), then:
                  <ol type="i">
                    <li>
                      subject to subclause (ii), the person who is employed at
                      the date of my death as the Estate Administrator for
                      Safewill Estates;
                    </li>

                    <li>
                      if the Estate Administrator is unable or unwilling to act
                      or continue to act as my Executor, or if their appointment
                      is unable to take effect, the person employed at the date
                      of my death as the Auxiliary Estate Administrator for
                      Safewill Estates.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          )}
          {hasProfessionalExecutors && (
            <li>
              <strong>Safewill Estates</strong> means Safewill Estates Pty Ltd
              ACN 648 912 163, or its successor company.
            </li>
          )}
          <li>
            <strong>Statement of Testamentary Wishes</strong> means the
            non-binding document signed by me that accompanies this Will or any
            other document signed by me titled Statement of Testamentary Wishes
            and stored with this Will or amongst my private papers to assist and
            guide my Executor with the administration of my estate and the
            carrying out of my intended wishes, but without intending to be
            binding on my Executor.
          </li>
          <li>
            <strong>Substitute Executor/s</strong> has the meaning ascribed to
            that term in the Appointment of Executors section of this Will.
          </li>
          <li>
            <strong>Will</strong> means this Will.
          </li>
        </ol>
        <h3>Interpretation:</h3>
        <ol type="a" start="1">
          <li>
            Headings in this Will are for convenience only and do not affect its
            interpretation or construction.
          </li>
          <li>
            In this Will, where a gift is given to a person who is less than 18
            years old, it is contingent on that person reaching that age, and
            does not vest until they reach that age. Until that person attains
            the age of 18, the relevant gift shall be held with my Executor on
            trust for, and applied for the benefit and maintenance of that
            person.
          </li>
          <li>Words in the singular include the plural and vice versa.</li>
          <li>
            Where the words <strong>&quot;include(s)&quot;</strong>,{' '}
            <strong>&quot;including&quot;</strong> or{' '}
            <strong>&quot;in particular&quot;</strong> are used in this Will
            they are deemed to have the words “without limitation” following
            them.
          </li>
          <li>
            A reference to one gender includes a reference to the other genders.
          </li>
          <li>
            All monetary amounts are in New Zealand currency, unless specified
            otherwise.
          </li>
          <li>
            References to recitals, clauses, subclauses, paragraphs, annexures
            or schedules are references to recitals, clauses, subclauses,
            paragraphs, annexures and schedules of or to this Will.
          </li>
        </ol>
      </ol>
    </article>
  );
};
