import React from 'react';
import { mf, identifiers, charityName, getPersonFullName } from '../index';

export default ({ will, beneficiaries: data, counter }) => {
  const getTextBackup = (beneficiary) => {
    const estateSplit = mf('estate_backup_split', beneficiary.meta);
    if (estateSplit === 'remaining')
      return `
        their share is to be added proportionately to the other share or shares
        in this Residuary Estate section which have not then failed.
      `;
    if (estateSplit === 'children')
      return (
        <>
          to their Children who survive me by 30 days and have attained or
          attain their Majority in equal shares (<b>Backup Beneficiary</b>).
        </>
      );
    if (estateSplit === 'custom') {
      return (
        <>
          their share is to be divided among those of the following who survive
          me by 30 days, and have attained or attain their Majority in the
          following proportions:
          {beneficiary.isBackupEstateSplitEvenly && (
            <ol type="i">
              {beneficiary.backup.map((bb, i) => {
                if (bb.directoryPerson) {
                  return (
                    <li key={i}>
                      1/{beneficiary.backup.length} to{' '}
                      {getPersonFullName(bb.directoryPerson)}{' '}
                      {identifiers(bb.directoryPerson)} (
                      <b>Backup Beneficiary</b>
                      ).
                    </li>
                  );
                }
                if (bb.charity) {
                  return (
                    <li key={i}>
                      1/{beneficiary.backup.length} to{' '}
                      {charityName(bb.charity.meta)} of{' '}
                      {mf('address', bb.charity.meta)} (
                      <b>Backup Beneficiary</b>).
                    </li>
                  );
                }
                if (bb.cause) {
                  return (
                    <li key={i}>
                      1/{beneficiary.backup.length} to be evenly divided amongst{' '}
                      the following organisations:
                      <ol type="1">
                        {bb.cause.charities.map((charity, cIdx) => {
                          return (
                            <li key={`${cIdx}-backup-cause-charity`}>
                              {charityName(charity.meta)} of{' '}
                              {mf('address', charity.meta)}
                            </li>
                          );
                        })}
                      </ol>
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          )}
          {!beneficiary.isBackupEstateSplitEvenly && (
            <ol type="i">
              {beneficiary.backup.map((bb, i) => {
                if (bb?.directoryPerson) {
                  return (
                    <li key={i}>
                      {bb.distribution}% to{' '}
                      {getPersonFullName(bb.directoryPerson)}{' '}
                      {identifiers(bb.directoryPerson)} (
                      <strong>Backup Beneficiary</strong>).
                    </li>
                  );
                }
                if (bb?.charity) {
                  return (
                    <li key={i}>
                      {bb.distribution}% to {charityName(bb.charity.meta)} of{' '}
                      {mf('address', bb.charity.meta)} (
                      <strong>Backup Beneficiary</strong>).
                    </li>
                  );
                }
                if (bb?.cause) {
                  return (
                    <li key={i}>
                      {bb.distribution}% to be evenly divided amongst the{' '}
                      following organisations:
                      <ol type="1">
                        {bb.cause.charities.map((charity, cIdx) => {
                          return (
                            <li key={`${cIdx}-backup-cause-charity`}>
                              {charityName(charity.meta)} of{' '}
                              {mf('address', charity.meta)}
                            </li>
                          );
                        })}
                      </ol>
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          )}
        </>
      );
    }
  };

  const beneficiaries = data.getBeneficiaries.filter((b) => b);
  const beneficiariesToCharities = beneficiaries.filter((b) => b?.charity);
  const beneficiariesToCauses = beneficiaries.filter((b) => b?.cause);
  const backupBeneficiariesToCharities = !!beneficiaries.find(
    (b) => !!b?.backup?.find((bb) => bb.charity)
  );
  const backupBeneficiariesToCauses = !!beneficiaries.find(
    (b) => !!b?.backup?.find((bb) => bb.cause)
  );

  return (
    <article id="residuary">
      <h2>Residuary Estate</h2>
      <strong>Residuary Estate Gifts</strong>
      <ol className="primary" start={counter.primary + 1}>
        <li data-counter={counter.primary++}>
          I give my Residuary Estate to my Executor to divide it among those of
          the following who survive me by 30 days, and have attained or attain
          their Majority in the following proportions:
          {will.isPrimaryEstateSplitEvenly && (
            <ol type="a">
              {beneficiaries.map((beneficiary, i) => {
                if (beneficiary.directoryPerson) {
                  return (
                    <li key={i}>
                      1/{beneficiaries.length} to{' '}
                      {getPersonFullName(beneficiary.directoryPerson)}{' '}
                      {identifiers(beneficiary.directoryPerson)} (
                      <b>Primary Beneficiary</b>) but if they do not survive me
                      by 30 days or do not inherit from me for any other reason,{' '}
                      {getTextBackup(beneficiary)}
                    </li>
                  );
                }
                if (beneficiary.charity) {
                  return (
                    <li key={i}>
                      1/{beneficiaries.length} to{' '}
                      {charityName(beneficiary.charity.meta)} of{' '}
                      {mf('address', beneficiary.charity.meta)} (
                      <strong>Primary Beneficiary</strong>).
                    </li>
                  );
                }
                if (beneficiary.cause) {
                  return (
                    <li key={i}>
                      1/{beneficiaries.length} to be evenly divided amongst the{' '}
                      following organisations:
                      <ol type="i">
                        {beneficiary.cause.charities.map((charity, cIdx) => {
                          return (
                            <li key={`${cIdx}-cause-charity`}>
                              {charityName(charity.meta)} of{' '}
                              {mf('address', charity.meta)}
                            </li>
                          );
                        })}
                      </ol>
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          )}
          {!will.isPrimaryEstateSplitEvenly && (
            <ol type="a">
              {beneficiaries.map((beneficiary, i) => {
                if (beneficiary.directoryPerson) {
                  return (
                    <li key={i}>
                      {beneficiary.distribution}% to{' '}
                      {getPersonFullName(beneficiary.directoryPerson)}{' '}
                      {identifiers(beneficiary.directoryPerson)} (
                      <strong>Primary Beneficiary</strong>) but if they do not
                      survive me by 30 days or do not inherit from me for any
                      other reason, {getTextBackup(beneficiary)}
                    </li>
                  );
                }
                if (beneficiary.charity) {
                  return (
                    <li key={i}>
                      {beneficiary.distribution}% to{' '}
                      {charityName(beneficiary.charity.meta)} of{' '}
                      {mf('address', beneficiary.charity.meta)}.
                    </li>
                  );
                }
                if (beneficiary.cause) {
                  return (
                    <li key={i}>
                      {beneficiary.distribution}% to be evenly divided amongst{' '}
                      the following organisations:
                      <ol type="i">
                        {beneficiary.cause.charities.map((charity, cIdx) => {
                          return (
                            <li key={`${cIdx}-cause-charity`}>
                              {charityName(charity.meta)} of{' '}
                              {mf('address', charity.meta)}
                            </li>
                          );
                        })}
                      </ol>
                    </li>
                  );
                }
                return null;
              })}
            </ol>
          )}
        </li>

        {(beneficiariesToCharities.length ||
          beneficiariesToCauses.length ||
          backupBeneficiariesToCharities ||
          backupBeneficiariesToCauses) && (
          <li data-counter={counter.primary++}>
            In respect of any charitable gifts in my Will:
            <ol type="a">
              <li>
                If an interest in a charitable gift cannot take effect
                completely or at all, to the extent that it cannot take effect,
                that interest shall pass to the charitable organisation or
                organisations in Australia which my Executor in their discretion
                considers most nearly fulfils or fulfil the objects I intend to
                benefit in the share or shares my Executor thinks fit; and
              </li>
              <li>
                The receipt of the treasurer, secretary or public officer for
                the time being of a beneficiary under this provision is a
                sufficient discharge to my Executor in respect of a gift to that
                beneficiary.
              </li>
            </ol>
          </li>
        )}
        <li data-counter={counter.primary++}>
          If any of the Backup Beneficiaries (being a person) do not survive me
          by 30 days then I direct my Executor to divide their share amongst
          their Children (if they have any) who survive them in equal shares{' '}
          <b>provided that</b> if the relevant Backup Beneficiary does not have
          any living Children, then I direct my Executor to divide their share
          proportionately among the other Backup Beneficiaries of the relevant
          Primary Beneficiary, whose shares have not then failed.
        </li>
        <li data-counter={counter.primary++}>
          If there is any part of my estate that is not gifted to a beneficiary
          under the preceding clauses of this Will, then the residue of my
          estate shall be gifted in accordance with the law of intestacy that
          would govern my estate if I died without a will <b>provided that</b>{' '}
          if the operation of the law of intestacy would result in the
          government being entitled to my estate, then the residue of my estate
          shall be gifted to such charities as my Executor chooses in my
          Executor&apos;s absolute discretion.
        </li>
      </ol>
    </article>
  );
};
