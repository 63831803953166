import dayjs from 'dayjs';
import { getName, registerLocale } from 'i18n-iso-countries';

export { default as NoWill } from './NoWill';

registerLocale(require('i18n-iso-countries/langs/en.json'));

// Extract value from key in meta.
export function mf(field, arr) {
  if (!arr || !arr.length) return null;
  let val = '';
  arr.forEach((a) => {
    if (a.key === field) val = a.value;
  });
  return val;
}

export function categoriseGifts(gifts) {
  const giftsToCharities = gifts.filter((g) => g && g.charity);
  const giftsToPeople = gifts.filter((g) => g && g.recipientDirectoryPerson);

  const giftsToCharitiesOfMoney = [];
  const giftsToCharitiesOfPossessions = [];
  const giftsToPeopleOfMoney = [];
  const giftsToPeopleOfPossessions = [];

  giftsToCharities.forEach((g) => {
    g.meta.forEach((gm) => {
      if (gm.key === 'type' && gm['value'] === 'money')
        giftsToCharitiesOfMoney.push(g);
      if (gm.key === 'type' && gm['value'] === 'possession')
        giftsToCharitiesOfPossessions.push(g);
    });
  });

  giftsToPeople.forEach((g) => {
    g.meta.forEach((gm) => {
      if (gm.key === 'type' && gm['value'] === 'money')
        giftsToPeopleOfMoney.push(g);
      if (gm.key === 'type' && gm['value'] === 'possession')
        giftsToPeopleOfPossessions.push(g);
    });
  });

  return {
    giftsToCharities,
    giftsToPeople,
    giftsToCharitiesOfMoney,
    giftsToCharitiesOfPossessions,
    giftsToPeopleOfMoney,
    giftsToPeopleOfPossessions
  };
}

export function formatMoney(val, country) {
  let countryNumberFormat = 'en-AU';
  let countryCurrency = 'AUD';
  if (country === 'nz') {
    countryNumberFormat = 'en-NZ';
    countryCurrency = 'NZD';
  }
  return Intl.NumberFormat(countryNumberFormat, {
    style: 'currency',
    currency: countryCurrency
  }).format(val);
}

export function getPersonFullName(directoryPerson) {
  return [
    directoryPerson?.firstName,
    directoryPerson?.middleName,
    directoryPerson?.lastName
  ]
    .filter(Boolean)
    .join(' ');
}

export function getPersonAltFullName(directoryPerson) {
  return [
    directoryPerson.altFirstName,
    ...(directoryPerson.altMiddleName ? [directoryPerson.altMiddleName] : []),
    directoryPerson.altLastName
  ].join(' ');
}

export function separateContactsByRole(user, directoryPersons) {
  const ownerPerson = directoryPersons.find(
    (person) => person.id === user.person_id
  );
  const partnerPersonId = ownerPerson.relationships.find(
    (r) => r.type === 'PARTNER'
  )?.toPersonId;
  const partnerPerson = directoryPersons.find(
    (person) => person.id === partnerPersonId
  );

  const childrenPersonIds = ownerPerson.relationships
    .filter((r) => r.type === 'CHILD')
    .map((r) => r.toPersonId);
  const childrenPersons = directoryPersons.filter((person) =>
    childrenPersonIds.includes(person.id)
  );

  return {
    ownerPerson,
    partnerPerson,
    childrenPersons
  };
}

export function isValidAddress(residentialAddress) {
  const { streetAddress, locality, postcode, region, country } =
    residentialAddress ?? {};
  if (!streetAddress || !locality || !postcode || !country) {
    return false;
  }
  return country === 'AU' ? !!region : true;
}

export function buildAddress({ residentialAddress }) {
  const { streetAddress, locality, postcode, region, country } =
    residentialAddress;
  const addressCountryName = getName(country, 'en') ?? country;

  return `of ${streetAddress}, ${locality} ${region} ${postcode} ${addressCountryName}`;
}

export function identifiers(directoryPerson) {
  let addressIdentifier;
  const otherIdentifiers = []; // DOB, Phone number, Email

  if (isValidAddress(directoryPerson.residentialAddress)) {
    addressIdentifier = buildAddress(directoryPerson);
  }

  if (directoryPerson.dateOfBirth) {
    const dateOfBirthFormatted = dayjs(directoryPerson.dateOfBirth).format(
      'D MMMM YYYY'
    );
    otherIdentifiers.push(`DOB: ${dateOfBirthFormatted}`);
  }
  if (directoryPerson.phoneNumbers.length > 0) {
    const defaultPhoneNumber = directoryPerson.phoneNumbers.filter(
      ({ type }) => type === 'DEFAULT'
    )[0];
    if (defaultPhoneNumber) {
      otherIdentifiers.push(
        `Ph: ${defaultPhoneNumber.prefix ?? ''}${defaultPhoneNumber.value}`
      );
    }
  }
  if (directoryPerson.emails.length > 0) {
    const defaultEmail = directoryPerson.emails.filter(
      ({ type }) => type === 'DEFAULT'
    )[0].value;
    otherIdentifiers.push(`Email: ${defaultEmail}`);
  }

  if (addressIdentifier && otherIdentifiers.length > 0) {
    return `${addressIdentifier} (${otherIdentifiers.join(', ')})`;
  }

  return addressIdentifier ?? `(${otherIdentifiers.join(', ')})`;
}

export function charityName(meta) {
  const abn = mf('abn', meta);
  const charityName = mf('name', meta);
  return abn ? `${charityName} (ABN: ${abn})` : charityName;
}

export function nzCharityName(meta) {
  const abn = mf('abn', meta);
  const charityName = mf('name', meta);
  return abn ? `${charityName} (Registration Number: ${abn})` : charityName;
}
