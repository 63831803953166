import React from 'react';
import { getPersonFullName, identifiers, mf } from '../index';

export default ({ will, pets, counter }) => {
  const petsWithPerson = pets.getPets.filter((p) => p?.guardianDirectoryPerson);
  const willMeta = will.meta;
  return (
    <article id="pets">
      {petsWithPerson.length > 0 && (
        <>
          <h2>Pets</h2>
          <ol className="primary" start={counter.primary + 1}>
            <li data-counter={counter.primary++}>
              <ol type="a">
                {petsWithPerson.map((pet, i) => (
                  <li key={i}>
                    If my pet {mf('type', pet.meta).toLowerCase()},{' '}
                    {mf('name', pet.meta)}, is alive at the date of my death, I
                    give ownership of this pet to{' '}
                    {getPersonFullName(pet.guardianDirectoryPerson)}{' '}
                    {identifiers(pet.guardianDirectoryPerson)}
                  </li>
                ))}
              </ol>
            </li>
            <li data-counter={counter.primary++}>
              If any of the persons listed above refuse or are unable to accept
              the responsibilities of ownership of my pet(s), then I give my
              Executor the absolute discretion to rehome my pet(s), at the
              earliest opportunity, in a permanent, safe and loving home.
            </li>
            <li data-counter={counter.primary++}>
              For any other pet that I own at my death, I give my Executor
              absolute discretion to rehome them as soon as possible.
            </li>
            <li data-counter={counter.primary++}>
              If at the date of my death, any pet of mine is unwell or suffering
              to the extent that it cannot be treated, or my Executor is unable
              to find them a suitable, permanent home after exhausting all
              options, then I give my Executor the fullest possible discretion
              to deal with that pet&apos;s welfare as they deem appropriate.
            </li>
            <li data-counter={counter.primary++}>
              To the extent that any expenses are incurred by my Executor in
              relation to the care, treatment and housing of any pet of mine, I
              declare that such expenses will be paid out of my Residuary
              Estate.
            </li>
            {mf('pet_care_fund', willMeta) && (
              <li data-counter={counter.primary++}>
                I give my Executor absolute discretion to make the gift of the
                Pet Guardianship Fund as set out in my Statement of Testamentary
                Wishes, as an expense of the estate or otherwise, without being
                liable to any of the beneficiaries of this Will who may have
                otherwise received an interest in the funds that comprise the
                Pet Guardianship Fund.
              </li>
            )}
          </ol>
        </>
      )}
    </article>
  );
};
