import React from 'react';
import { mf, identifiers, getPersonFullName } from '../index';

export default ({ will, executors: data, counter }) => {
  const meta = will.meta;
  const executors = data.getExecutors;
  const primaryExecutors = executors.filter((e) => e && e.type === 'primary');
  const primaryExecutorPerson1 = primaryExecutors[0]?.directoryPerson;
  const primaryExecutorPerson2 = primaryExecutors[1]?.directoryPerson;

  const backupExecutors = executors.filter((e) => e && e.type === 'backup');
  const backupExecutorPerson1 = backupExecutors[0]?.directoryPerson;
  const backupExecutorPerson2 = backupExecutors[1]?.directoryPerson;

  return (
    <article id="executors">
      <h2>Appointment of Executors</h2>
      <ol className="primary" start={counter.primary + 1}>
        {(mf('executors_option', meta) === 'professional' ||
          mf('executors_option', meta) === 'friendsFamilyAndProfessional') && (
          <li data-counter={counter.primary++}>
            I appoint as my executor and trustee the Safewill Executor at the
            date of my death (‘my <strong>Executor</strong>’, which includes the
            executor of this Will and the trustee of my estate from time to
            time).
          </li>
        )}
        {mf('executors_option', meta) === 'friendsFamily' && (
          <>
            {primaryExecutors.length === 1 && (
              <li data-counter={counter.primary++}>
                I appoint as Executor of my Will{' '}
                {getPersonFullName(primaryExecutorPerson1)}{' '}
                {identifiers(primaryExecutorPerson1)}.
              </li>
            )}
            {primaryExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                I appoint as Executor of my Will{' '}
                {getPersonFullName(primaryExecutorPerson1)}{' '}
                {identifiers(primaryExecutorPerson1)} and{' '}
                {getPersonFullName(primaryExecutorPerson2)}{' '}
                {identifiers(primaryExecutorPerson2)} jointly.
              </li>
            )}
            {primaryExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                If one of my Executors in the preceding clause refuses or is
                unwilling or unable to act as my Executor, the remaining
                Executor may continue to act as sole Executor.
              </li>
            )}
          </>
        )}

        {mf('executors_option', meta) === 'friendsFamilyAndProfessional' && (
          <>
            {primaryExecutors.length === 1 && (
              <li data-counter={counter.primary++}>
                If my Executor in the preceding clause refuses or is unwilling
                or unable to act as my Executor, I appoint as my Executor{' '}
                {getPersonFullName(primaryExecutorPerson1)}{' '}
                {identifiers(primaryExecutorPerson1)}.
              </li>
            )}
            {primaryExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                If my Executor in the preceding clause refuses or is unwilling
                or unable to act as my Executor, I appoint as my Executors{' '}
                {getPersonFullName(primaryExecutorPerson1)}{' '}
                {identifiers(primaryExecutorPerson1)} and{' '}
                {getPersonFullName(primaryExecutorPerson2)}{' '}
                {identifiers(primaryExecutorPerson2)} jointly (my{' '}
                <b>Substitute Executors</b>).
              </li>
            )}
            {primaryExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                If one of my Substitute Executors refuses or is unwilling or
                unable to act as my Executor, the remaining Substitute Executor
                may continue to act as sole Executor.
              </li>
            )}
          </>
        )}

        {(mf('executors_option', meta) === 'friendsFamily' ||
          mf('executors_option', meta) === 'professional') && (
          <>
            {backupExecutors.length === 1 && (
              <li data-counter={counter.primary++}>
                If my Executor (or the remaining Executor, as the case may be)
                in the preceding clause refuses or is unwilling or unable to act
                as my Executor, I appoint as my Executor{' '}
                {getPersonFullName(backupExecutorPerson1)}{' '}
                {identifiers(backupExecutorPerson1)}.
              </li>
            )}
            {backupExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                If my Executor (or the remaining Executor, as the case may be)
                in the preceding clause refuses or is unwilling or unable to act
                as my Executor, I appoint as my Executors{' '}
                {getPersonFullName(backupExecutorPerson1)}{' '}
                {identifiers(backupExecutorPerson1)} and{' '}
                {getPersonFullName(backupExecutorPerson2)}{' '}
                {identifiers(backupExecutorPerson2)} jointly (my{' '}
                <b>Substitute Executors</b>).
              </li>
            )}
            {backupExecutors.length === 2 && (
              <li data-counter={counter.primary++}>
                If one of my Substitute Executors refuses or is unwilling or
                unable to act as my Executor, the remaining Substitute Executor
                may continue to act as sole Executor.
              </li>
            )}
          </>
        )}
        <li data-counter={counter.primary++}>
          Any gifts to my Executors are not dependent upon my Executors acting
          as executor, and this section does not prevent my Executors from
          applying to the court for commission.
        </li>
        <li data-counter={counter.primary++}>
          I nominate and appoint my Executors to exercise all powers of
          appointment held by me.
        </li>
      </ol>

      <h2>Powers of Executor</h2>
      <h3>General Powers of Executor</h3>
      <ol className="primary" start={counter.primary + 1}>
        <li data-counter={counter.primary++}>
          In addition to any powers or privileges bestowed upon my Executor by
          law, my Executor will have absolute discretion to exercise any powers
          in respect to my estate (including the acquisition, varying,
          transposing, disposing, surrender or forfeiture of any property of my
          estate) that are capable of being bestowed on an executor or trustee
          by any valid will or instrument, as though my Executor were an
          individual and legal and beneficial owner of my estate acting in their
          personal capacity so as to carry out my instructions and wishes in
          accordance with this Will and in good faith for the benefit of the
          beneficiaries.
        </li>
        <li data-counter={counter.primary++}>
          Any of my Executors who practises a profession is entitled to be paid
          all usual and reasonable professional fees for work done (as executor
          or trustee or both) by that person or her or his firm on the same
          basis as if he or she were not one of my Executors but employed to act
          on behalf of my Executors.
        </li>
        <li data-counter={counter.primary++}>
          My Executor may take and act upon the opinion of any legal counsel of
          at least five years standing whether in relation to the interpretation
          of this Will or any other document or statute or as to the
          administration of the trusts hereof without being liable to any of the
          persons beneficially interested in respect of any act done by them in
          accordance with such opinion provided that such action does not result
          in a breach of trust arising from my Executor’s dishonesty, wilful
          misconduct or gross negligence.
        </li>
        {(mf('executors_option', meta) === 'professional' ||
          mf('executors_option', meta) === 'friendsFamilyAndProfessional') && (
          <>
            <li data-counter={counter.primary++}>
              I declare that the terms and conditions for the appointment of the
              Safewill Executor (‘the <strong>Terms</strong>’) as updated from
              time to time shall apply to this my Will if the Safewill Executor
              is appointed as my Executor in any capacity. To the extent that
              there is any inconsistency between the terms of this my Will, and
              the Terms, or to the extent that the Terms do not or cannot apply
              I declare that the terms of this my Will shall apply.
            </li>
            <li data-counter={counter.primary++}>
              In the administration of my Estate, I authorise Safewill Estates
              to charge and retain from my Estate the fees at the rate set out
              and in accordance with the published Terms. I declare that the
              Terms (including the associated fees) were disclosed to me on the
              Safewill Platform and read by me in full prior to the execution of
              my Will, and that I had the opportunity to obtain legal advice in
              relation to the Terms. I declare that the fees set out in the
              Terms most recently published and accepted on my account will
              apply, and that these rates may be charged instead of the rates
              allowed under the Corporations Act 2001 (Cth).
            </li>
            <li data-counter={counter.primary++}>
              I understand that in appointing the Safewill Executor, I have
              appointed an Executor that will charge fees in relation to the
              administration of my Estate (as set out in the Terms). I also
              understand that I could appoint an executor of my Will who might
              make no claim for commission or payment in relation to the
              administration of my Estate. I have had the opportunity to
              consider and obtain advice about the Terms and their effect prior
              to the execution of this Will and I have chosen to make the
              appointment of the Safewill Executor.
            </li>
            <li data-counter={counter.primary++}>
              I further acknowledge and agree that where relevant:
              <ol type="a">
                <li>
                  the Estate Administrator and Auxiliary Estate Administrator
                  are employees of Safewill Estates;
                </li>
                <li>
                  the Estate Administrator or Auxiliary Estate Administrator (as
                  the case may be) will not charge a fee for their executorial
                  services in their personal capacity, but that the fees
                  pursuant to the Terms are charged by and payable to Safewill
                  Estates for work done by the Estate Administrator or Auxiliary
                  Estate Administrator (as the case may be) as an employee of
                  Safewill Estates;
                </li>
                <li>
                  the Estate Administrator or Auxiliary Estate Administrator (as
                  the case may be) can delegate or employ Safewill Third Parties
                  to perform estate related services and that those Safewill
                  Third Parties will charge a fee for those services.
                </li>
              </ol>
            </li>
            <li data-counter={counter.primary++}>
              I authorise my Executor to engage partners and affiliates of
              Safewill Estates (‘<strong>the Safewill Third Parties</strong>’)
              to assist with and advise on the administration of my Estate and
              trusts that may result, as they see fit and without notice to my
              beneficiaries. I give this authorisation despite the fact that my
              Executor may receive a direct or indirect benefit from such
              engagement, but provided that the fees charged by the Safewill
              Third Parties are reasonable in all the circumstances,
              particularly in light of the market value of the services
              provided. If such work is undertaken by Safewill Third Parties,
              their fees shall be in addition to the fees charged pursuant to
              the Terms.
            </li>
            <li data-counter={counter.primary++}>
              I authorise my Executor to employ and pay an agent or agents to
              transact any business or do any act required to be transacted in
              relation to the grant of representation of my Estate, the
              administration of my Estate or execution of any trusts that
              result, without notice to my beneficiaries. Any expenses incurred
              by such employment shall be paid from my Estate and are in
              addition to my Executor&apos;s fees referred to above. My Executor
              may take and act upon the opinion or advice of any such
              professional without being liable to any of the persons
              beneficially interested in respect of any act done by them in
              accordance with such opinion.
            </li>
            <li data-counter={counter.primary++}>
              I request but do not require my Executor to use Safewill Legal Pty
              Ltd (ACN 648 090 297) or its successor organisation as solicitors
              for my Estate.
            </li>
            <li data-counter={counter.primary++}>
              My Executor is not liable for any loss to the estate unless that
              loss was caused by their own actual fraud or negligence.
            </li>
            <li data-counter={counter.primary++}>
              It is my wish, but I do not require, that in considering and
              making investments and managing my Estate, my Executor obtains and
              considers the advice of an appropriately qualified person in good
              standing who is experienced and knowledgeable in financial and
              investment planning and that the fees of that person will be paid
              for by my Estate.
            </li>
          </>
        )}
      </ol>

      <h3>Specific Powers of Executor</h3>
      <ol className="primary" start={counter.primary + 1}>
        <li data-counter={counter.primary++}>
          Without limiting the general powers in the preceding clause, my
          Executor will have the powers to do any of the following on any terms
          as my Executor deems appropriate in their absolute discretion so as to
          carry out my instructions and wishes in accordance with this Will and
          in good faith for the benefit of the beneficiaries:
          <ol type="a">
            <li>
              <strong>Appropriation:</strong> appropriate any asset of the
              estate not specifically given, without the consent of any
              beneficiary, in full or partial satisfaction of a legacy or share
              of residue, and in doing so the following provisions apply:
              <ol type="i">
                <li>
                  the value of any such asset is that agreed by those
                  beneficiaries affected or, if my Executors are satisfied, in
                  their opinion, that no value can be agreed in this way, the
                  value is that determined by an independent valuer appointed by
                  my Executors for that purpose;
                </li>
                <li>
                  my Executors need not take into account any differences in
                  value of particular assets to particular beneficiaries other
                  than the value of the asset as decided pursuant to the
                  previous subclause.
                </li>
              </ol>
            </li>
            <li>
              <strong>Asset Sale or postponement:</strong> exercise all
              statutory and other powers pertaining to my estate as would be
              exercisable by my Executor if my Executor were given the estate
              upon trust for sale, including:
              <ol type="i">
                <li>
                  the sale, leasing, converting or disposing of any assets of my
                  estate in such manner and at such price as my Executor deems
                  appropriate; or
                </li>
                <li>
                  the postponing of the sale, calling in and conversion of the
                  estate or any part of it,
                </li>
              </ol>
              for so long as my Executor deems appropriate, without being liable
              to account for any loss (except where the loss arises from my
              Executors dishonesty, wilful misconduct or gross negligence).
            </li>
            <li>
              <strong>Bank accounts:</strong> open, operate or close any bank
              accounts with any bank.
            </li>
            <li>
              <strong>Borrowing money:</strong> borrow or raise money (with or
              without security) from any person, entity or beneficiary, and
              enter into any mortgage, charge, lien, security interest or
              otherwise over any part of my estate.
            </li>
            <li>
              <strong>Business:</strong> deal with any business in respect of
              which I was engaged or had an interest in at the time of my death,
              as if my Executor were the absolute owner of that business,
              including continuing or discontinuing the business, increasing or
              reducing the capital, acquiring or carrying on the business, and
              any liability or loss incurred by Executor in doing so will be
              reimbursed to my Executor from my estate.
            </li>
            <li>
              <strong>Dealings:</strong> use, hold, lease, surrender, grant and
              receive options or rights in, or deal with my estate, including
              units, shares, bonds, convertible notes, safe notes, securities or
              debentures of any company on trust.
            </li>
            <li>
              <strong>Directors:</strong> act as or become a director of any
              company in which my estate has an interest in, holds shares in or
              may be concerned with and receive remuneration, including
              exercising any voting rights as my Executor deems appropriate.
            </li>
            <li>
              <strong>Insurance:</strong> maintain or take out any insurance
              policies as my Executor deems appropriate for the benefit of my
              estate or any beneficiary of my estate and convert (where
              applicable) any insurance proceeds or monies for the benefit of my
              estate or beneficiary of my estate.
            </li>
            <li>
              <strong>Income and Capital:</strong> determine or calculate the
              value of any income, capital or other assets of my estate pursuant
              to acceptable tax or accounting standards, and use such income or
              capital from my estate:
              <ol type="i">
                <li>
                  for the benefit of my estate and any beneficiary as my
                  Executor deems appropriate;
                </li>
                <li>
                  to pay any debts, liabilities, duties or taxes (including
                  capital gains tax) arising in the administration of my estate
                  and apportion liability for such tax among the beneficiaries
                  as my Executor deems appropriate.
                </li>
              </ol>
            </li>
            <li>
              <strong>Investment:</strong> Invest the estate or any part of it
              in any form of Investment (whether such investment produces income
              or not or is deemed to be prudent or appropriate by law) as my
              Executor deems appropriate, and to vary or modify such investment.
            </li>
            <li>
              <strong>Loans:</strong> make loans to any person or beneficiary
              (whether secured or unsecured) on terms my Executor deems
              appropriate.
            </li>
            <li>
              <strong>Maintenance:</strong> apply any part of the share of any
              beneficiary or any person considered to be a beneficiary by my
              Executors for the maintenance, support, education, advancement or
              benefit of that person, including the power to make payments to a
              minor beneficiary&apos;s parent or guardian or a person with whom
              the minor beneficiary resides, contingent beneficiary or
              incapacitated beneficiary, and my Executor shall not be
              responsible for the application of such maintenance, income or
              capital.
            </li>
            <li>
              <strong>Occupation:</strong> allow any beneficiary to have custody
              of or occupy any real or personal property for the time being
              forming part of the estate on terms as my Executor deems
              appropriate without my Executor being responsible for any loss
              (except where the loss arises from my Executors dishonesty, wilful
              misconduct or gross negligence).
            </li>
            <li>
              <strong>Repairs and Renovations:</strong> maintain, repair,
              improve, develop, alter, renovate any part of my estate as my
              Executor deems appropriate.
            </li>
            <li>
              <strong>Use or enjoyment by a beneficiary:</strong> lease or
              acquire any assets for a beneficiary for their use and enjoyment
              (including any other person who resides with that beneficiary).
            </li>
          </ol>
        </li>
      </ol>
    </article>
  );
};
