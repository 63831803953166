const DirectoryPersonFragment = `
  fragment DirectoryPerson on DirectoryPerson {
    id
    firstName
    middleName
    lastName
    altFirstName
    altMiddleName
    altLastName
    dateOfBirth
    countryOfBirth
    sex
    religion
    emails {
      type
      value
      verified
    }
    phoneNumbers {
      type
      prefix
      value
    }
    residentialAddress {
      id
      streetAddress
      extendedAddress
      locality
      postcode
      region
      country
    }
    ageEighteenOrAbove
  }
`;

export const GET_WILL = {
  query: `{
    getWill {
      will {
        id
        userId
        isPrimaryEstateSplitEvenly
        meta {
          key
          value
        }
      }
    }
  }`
};

export const CURRENT_USER = `
  query currentUser {
    currentUser {
      message
      user {
        id
        person_id
      }
    }
  }
`;

export const EXECUTORS = `
  ${DirectoryPersonFragment}
  query GetExecutors($willId: ID!) {
    getExecutors(willId: $willId) {
      id
      type
      meta {
        key
        value
      }
      directoryPerson {
        ...DirectoryPerson
      }
    }
  }
`;

export const PETS = `
  ${DirectoryPersonFragment}
  query GetPets($willId: ID!) {
    getPets(willId: $willId) {
      id
      meta {
        key
        value
      }
      guardianDirectoryPerson {
        ...DirectoryPerson
      }
    }
  }
`;

export const GUARDIANS = `
  ${DirectoryPersonFragment}
  query GetGuardians($willId: ID!) {
    getGuardians(willId: $willId) {
      id
      type
      meta {
        key
        value
      }
      directoryPerson {
        ...DirectoryPerson
      }
    }
  }
`;

export const GIFTS = `
  ${DirectoryPersonFragment}
  query GetGifts($willId: ID!) {
    getGifts(willId: $willId) {
      id
      meta {
        key
        value
      }
      recipientDirectoryPerson {
        ...DirectoryPerson
      }
      charity {
        id
        meta {
          key
          value
        }
      }
    }
  }
`;

export const BENEFICIARIES = `
  ${DirectoryPersonFragment}
  query GetBeneficiaries($willId: ID!) {
    getBeneficiaries(willId: $willId) {
      id
      distribution
      isBackupEstateSplitEvenly
      meta {
        key
        value
      }
      directoryPerson {
        ...DirectoryPerson
      }
      charity {
        id
        meta {
          key
          value
        }
      }
      cause {
        displayName
        charities {
          meta {
            key
            value
          }
        }
      }
      backup {
        id
        distribution
        meta {
          key
          value
        }
        directoryPerson {
          ...DirectoryPerson
        }
        cause {
          displayName
          charities {
            meta {
              key
              value
            }
          }
        }
        charity {
          id
          meta {
            key
            value
          }
        }
      }
    }
  }
`;

export const DIRECTORY_PERSONS = `
  ${DirectoryPersonFragment}
  query GetDirectoryPersons($ownerId: ID!, $excludeSelf: Boolean!) {
    getDirectoryPersonsByOwnerId(ownerId: $ownerId, excludeSelf: $excludeSelf) {
      ...DirectoryPerson
      relationships {
        type
        toPersonId
        fromPersonId
      }
    }
  }
`;

export const ASSETS = `
  query GetAssets($willId: ID!) {
    getAssets(willId: $willId) {
      id
      meta {
        key
        value
      }
    }
  }
`;

export const LIABILITIES = `
  query GetLiabilities($willId: ID!) {
    getLiabilities(willId: $willId) {
      id
      meta {
        key
        value
      }
    }
  }
`;
