import React from 'react';
import {
  mf,
  formatMoney,
  nzCharityName as charityName,
  getPersonFullName
} from '../index';

export default ({
  will,
  gifts: giftData,
  assets: assetData,
  liabilities: liabilityData
}) => {
  const meta = will.meta;

  const gifts = giftData.getGifts;
  const assets = assetData.getAssets;
  const liabilities = liabilityData.getLiabilities;

  const giftsWithNote = [];
  gifts.forEach((g) => {
    g.meta.forEach((gm) => {
      if (gm.key === 'note') {
        giftsWithNote.push(g);
      }
    });
  });

  return (
    <article id="wishes" className="page-break">
      <h1>Statement of Testamentary Wishes</h1>
      <p>
        This Statement of Testamentary Wishes is a non-binding document that has
        been placed with this Will to assist my Executor in preparing my
        funeral, finding my financial assets and delivering personal messages I
        have written to my beneficiaries.
      </p>
      <p>
        I understand that the below notes are provided to guide my executor,
        without intending to impose any legal obligation upon them, and without
        intending to form part of my Will.
      </p>
      <ol className="wishes">
        {mf('executors_option', meta) === 'friendsFamilyAndProfessional' && (
          <li>
            <p>
              I wish for my Executor to consult with my Substitute Executor(s)
              where possible from time to time during the administration of my
              estate and any resulting testamentary trusts.
            </p>
          </li>
        )}

        {mf('pet_care_fund', meta) && (
          <li>
            <h3>Pet Guardianship fund</h3>
            <p>
              I wish for my Executor to provide a maximum of{' '}
              {formatMoney(mf('pet_care_fund', meta), 'nz')} out of my Residuary
              Estate as a one-time payment to whoever assumes care of my pets,
              to be used for the future care, feeding and maintenance of those
              pets.
            </p>
            <p>
              If there is more than one person who assumes care of my pets then
              the amount of {formatMoney(mf('pet_care_fund', meta), 'nz')}{' '}
              should be split equally between those persons.
            </p>
          </li>
        )}

        {(assets.length > 0 || liabilities.length > 0) && (
          <li>
            <h3>Asset and Liability Instructions</h3>
            <p>
              I have included details of my financial assets and liabilities to
              help my Executor to administer my estate. This list is accurate at
              the time of writing, though not necessarily exhaustive, and
              appropriate efforts should still be made to locate additional
              assets and liabilities that may not be listed here:
            </p>
            <p>
              <strong>Assets:</strong>
            </p>
            <ul>
              {assets.map((a, i) => (
                <li key={i}>
                  <strong>{mf('type', a.meta)}:</strong>{' '}
                  {mf('description', a.meta)}
                </li>
              ))}
            </ul>
            <p>
              <strong>Liabilities:</strong>
            </p>
            <ul>
              {liabilities.map((l, i) => (
                <li key={i}>
                  <strong>{mf('type', l.meta)}:</strong>{' '}
                  {mf('description', l.meta)}
                </li>
              ))}
            </ul>
          </li>
        )}

        {(assets.length > 0 || liabilities.length > 0) &&
          mf('asset_note', meta) && (
            <li>
              <p>
                <strong>
                  I leave the following notes for my executor regarding my
                  assets and liabilities:
                </strong>
              </p>
              <p>{mf('asset_note', meta)}</p>
            </li>
          )}

        {mf('funeral_note', meta) && (
          <li>
            <h3>Funeral Wishes</h3>
            <p>
              <strong>My wishes for my funeral are as follows:</strong>
            </p>
            <p>{mf('funeral_note', meta)}</p>
          </li>
        )}

        {giftsWithNote.length > 0 && (
          <li>
            <h3>Messages</h3>
            <ul>
              {giftsWithNote.map(
                (gift, i) =>
                  (gift.recipientDirectoryPerson && (
                    <li key={i}>
                      <strong>
                        To {getPersonFullName(gift.recipientDirectoryPerson)}{' '}
                        about my gift{' '}
                        {mf('type', gift.meta) === 'possession'
                          ? mf('description', gift.meta)
                          : 'of ' + formatMoney(mf('amount', gift.meta), 'nz')}
                        :
                      </strong>
                      <p>{mf('note', gift.meta)}</p>
                    </li>
                  )) ||
                  (gift.charity && (
                    <li key={i}>
                      <strong>
                        To {charityName(gift.charity.meta)} about my gift{' '}
                        {mf('amount', gift.meta) ? 'of ' : ''}
                        {mf('type', gift.meta) === 'possession'
                          ? mf('description', gift.meta)
                          : formatMoney(mf('amount', gift.meta), 'nz')}
                        :
                      </strong>
                      <p>{mf('note', gift.meta)}</p>
                    </li>
                  ))
              )}
            </ul>
          </li>
        )}
      </ol>
    </article>
  );
};
