import React, { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import {
  GET_WILL,
  CURRENT_USER,
  BENEFICIARIES,
  EXECUTORS,
  GUARDIANS,
  PETS,
  GIFTS,
  ASSETS,
  LIABILITIES,
  DIRECTORY_PERSONS
} from './queries';
import { detokenise } from './utils/detokenise';

import { NoWill } from './pages';

import AuWillMain from './pages/au/WillMain';
import AuWillTestamentaryWishes from './pages/au/WillTestamentaryWishes';
import NzWillMain from './pages/nz/WillMain';
import NzWillTestamentaryWishes from './pages/nz/WillTestamentaryWishes';

import { Routes, Route } from 'react-router-dom';

const WillWrapper = ({ will }) => {
  const [detokenisedData, setDetokenisedData] = useState();
  const [detokeniseError, setDetokeniseError] = useState(false);
  const variables = { willId: will.id };

  const [resultUser] = useQuery({ query: CURRENT_USER });
  const [resultBeneficiaries] = useQuery({ query: BENEFICIARIES, variables });
  const [resultExecutors] = useQuery({ query: EXECUTORS, variables });
  const [resultGuardians] = useQuery({ query: GUARDIANS, variables });
  const [resultPets] = useQuery({ query: PETS, variables });
  const [resultGifts] = useQuery({ query: GIFTS, variables });

  const [resultAssets] = useQuery({ query: ASSETS, variables });
  const [resultLiabilities] = useQuery({ query: LIABILITIES, variables });
  const [resultDirectoryPersons] = useQuery({
    query: DIRECTORY_PERSONS,
    variables: {
      ownerId: will.userId,
      excludeSelf: false
    }
  });

  const fetching =
    resultUser.fetching ||
    resultBeneficiaries.fetching ||
    resultExecutors.fetching ||
    resultGuardians.fetching ||
    resultPets.fetching ||
    resultGifts.fetching ||
    resultAssets.fetching ||
    resultLiabilities.fetching ||
    resultDirectoryPersons.fetching;
  const error =
    resultUser.error ||
    resultBeneficiaries.error ||
    resultExecutors.error ||
    resultGuardians.error ||
    resultPets.error ||
    resultGifts.error ||
    resultAssets.error ||
    resultLiabilities.error ||
    resultDirectoryPersons.error;

  useEffect(() => {
    setDetokeniseError(false);

    if (!fetching) {
      detokenise({
        contacts: resultDirectoryPersons.data.getDirectoryPersonsByOwnerId,
        executors: resultExecutors.data,
        pets: resultPets.data,
        guardians: resultGuardians.data,
        gifts: resultGifts.data,
        beneficiaries: resultBeneficiaries.data
      })
        .then((data) => {
          setDetokenisedData(data);
        })
        .catch((e) => {
          setDetokeniseError(e);
        });
    }
  }, [fetching]);

  if (error || detokeniseError) {
    console.log(error || detokeniseError);
    return (
      <div>
        An unexpected error occurred - Please contact Safewill support at
        support@safewill.com.
      </div>
    );
  }
  if (fetching || !detokenisedData) return <div className="loader"></div>;

  const user = resultUser.data.currentUser.user;
  const { contacts, executors, pets, guardians, gifts, beneficiaries } =
    detokenisedData;

  return (
    <Routes>
      <Route
        path="/au/will/main"
        element={
          <AuWillMain
            will={will}
            user={user}
            beneficiaries={beneficiaries}
            contacts={contacts}
            executors={executors}
            guardians={guardians}
            pets={pets}
            gifts={gifts}
          />
        }
      />
      <Route
        path="/au/will/testamentary-wishes"
        element={
          <AuWillTestamentaryWishes
            will={will}
            gifts={gifts}
            assets={resultAssets.data}
            liabilities={resultLiabilities.data}
          />
        }
      />
      {/*nz routes */}
      <Route
        path="/nz/will/main"
        element={
          <NzWillMain
            will={will}
            user={user}
            beneficiaries={beneficiaries}
            contacts={contacts}
            executors={executors}
            guardians={guardians}
            pets={pets}
            gifts={gifts}
          />
        }
      />
      <Route
        path="/nz/will/testamentary-wishes"
        element={
          <NzWillTestamentaryWishes
            will={will}
            gifts={gifts}
            assets={resultAssets.data}
            liabilities={resultLiabilities.data}
          />
        }
      />
    </Routes>
  );
};

export default () => {
  const [result] = useQuery(GET_WILL);
  const { fetching, data } = result;

  if (fetching || !data) return <div className="loader"></div>;
  if (!data.getWill) return <NoWill />;

  return <WillWrapper will={data.getWill.will} />;
};
