import Wishes from './10-Wishes';
import React from 'react';

export default ({ will, gifts, assets, liabilities }) => {
  return (
    <div className="container">
      <Wishes
        will={will}
        gifts={gifts}
        assets={assets}
        liabilities={liabilities}
      />
    </div>
  );
};
