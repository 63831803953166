import Preliminary from './1-Preliminary';
import Definitions from './2-Definitions';
import Executors from './3-Executors';
import Guardians from './4-Guardians';
import Pets from './5-Pets';
import Gifts from './6-Gifts';
import Residuary from './7-Residuary';
import CommonProvisions from './8-CommonProvisions';
import Attestation from './9-Attestation';
import React from 'react';

export default ({
  will,
  user,
  beneficiaries,
  contacts,
  executors,
  guardians,
  pets,
  gifts
}) => {
  const counter = {
    primary: 0
  };

  return (
    <div className="container">
      <Preliminary
        will={will}
        user={user}
        contacts={contacts}
        counter={counter}
      />
      <Definitions will={will} counter={counter} />
      <Executors will={will} executors={executors} counter={counter} />
      <Guardians guardians={guardians} counter={counter} />
      <Pets will={will} pets={pets} counter={counter} />
      <Gifts gifts={gifts} counter={counter} />
      <Residuary will={will} beneficiaries={beneficiaries} counter={counter} />
      <CommonProvisions counter={counter} />
      <Attestation user={user} contacts={contacts} />
    </div>
  );
};
