import React from 'react';

export default ({ counter }) => (
  <article id="common-provisions">
    <h2>Common provisions</h2>
    <ol className="primary" start={counter.primary + 1}>
      <li data-counter={counter.primary++}>
        In respect of any gift of property under this will:
        <ol type="a">
          <li>
            If I die in possession of, or entitled to, or under a general power
            of appointment dispose of, an interest in property which at the time
            of my death is charged with the payment of a particular debt
            (whether by way of mortgage, charge, or otherwise legal or equitable
            including a lien for unpaid purchase money) (
            <strong>Encumbered Property</strong>):
            <ol type="i">
              <li>
                The Encumbered Property is primarily liable for the payment of
                the respective debt claimed by the respective debtors claiming
                against me; and
              </li>
              <li>
                Every part of the Encumbered Property, according to its value,
                bears a proportionate part of the charge of the respective debt.
              </li>
            </ol>
          </li>
          <li>
            If my Executor pays or is liable to pay a greater portion of the
            debt than their interest in the property would justify, my Executor
            is entitled to contribution from the others who hold interests in
            that property.
          </li>
          <li>
            Unless otherwise stated in my Will, my Executor must pay the expense
            of keeping and delivering any property to a beneficiary as though it
            was a testamentary expense (and is entitled to be reimbursed from my
            estate for such expense).
          </li>
          <li>
            Unless otherwise specifically stated elsewhere in my Will, I direct
            my Executor to sell, call in or convert into money any part of my
            estate and pay any of my funeral and testamentary expenses, just and
            reasonable debts, and any other expenses associated with my death or
            the administration of my estate and my Will, including probate,
            estate or succession duties or taxes payable in relation to my
            estate.
          </li>
          <li>
            Any proceeds that are not required for use as specified in the
            preceding clause shall be paid to my estate and shall form part of
            my Residuary Estate.
          </li>
          <li>
            I express the wish that my Executor make available and give to those
            of my beneficiaries who inherit any property all documents relevant
            to that property or the assessment of tax relating to that property.
          </li>
        </ol>
      </li>
    </ol>
  </article>
);
